import React, { useEffect, useRef, useState } from 'react';
import './donate.css'

const Donate = () => {
    return (
        <div>
            <a target = "_blank" href="https://link.clover.com/urlshortener/2mrHc7" className='donate'>Donate now!</a>
        </div>
    )
}

export default Donate;  